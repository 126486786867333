import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { PartReviewCard } from '../../../ui';

import styles from './styles';
import { Typography } from '@mui/material';

const CarouselItem = ({ items, selectedItemId, handleClassificationChange, loadingMoreProducts }) => {
  const user = useSelector((state) => state.currentUser);
  const classes = styles();
  return (
    <Box component="ul" className={user.marketPlaceInventoryView ? classes.carouselContainer : classes.carouselContainerGridView}>
      {items.map((item) => (
        <PartReviewCard
          key={`part-card-${item._id}`}
          myimage={item.Image}
          pn={item.PN}
          description={item.Description}
          category={item.Category}
          name={item.Name}
          classification={item.Classification}
          price={item.Price}
          isSelected={item._id === selectedItemId}
          itemId={item._id}
          handleClassificationChange={handleClassificationChange}
          isFavorite={JSON.parse(item?.isFavorite)}
        />
      ))}
      {loadingMoreProducts
        ? (
        <Box sx={{ display: 'flex', position: 'fixed', bottom: 0, justifyContent: 'center', alignItems: 'center', left: 0, right: 0, backgroundColor: '#FFF', padding: '14px 0' }}>
          <Typography variant="h6" fontWeight="bolder">Loading more..</Typography>
        </Box>
          )
        : null}
    </Box>
  );
};

CarouselItem.propTypes = {
  items: PropTypes.array,
  selectedItemId: PropTypes.string,
  loadingMoreProducts: PropTypes.bool,
  handleClassificationChange: PropTypes.func.isRequired
};

export default CarouselItem;
