import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import {
  PersonAddAltRounded,
  AspectRatioRounded
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import {
  addNewUsersRow,
} from '../../../../store/actions/users';
import { USER_TYPE } from '../../../../utils/constants';
import styles from './styles';

const AlarmsControls = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const users = useSelector((state) => state.users);
  const { preferences, type } = useSelector((state) => state.currentUser);

  const handleAspectRatio = useAspectRatio();

  const handleAddToAlarms = () => {
    dispatch(addNewUsersRow());
  };

  return (
    <Box sx={{ marginLeft: 'auto' }}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Box className={classes.container}>
            <Box>
              {type === USER_TYPE.PREMIUM_ADMIN || type === USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN
                ? (
                  <IconButton
                    disabled={users.loading}
                    size="large"
                    aria-label="orders"
                    onClick={handleAddToAlarms}
                  >
                    <PersonAddAltRounded />
                  </IconButton>
                  )
                : null}
            </Box>
            <Box>
              <IconButton
                edge="end"
                onClick={() => {
                  handleAspectRatio(!preferences.aspectChanged);
                }}
              >
                <AspectRatioRounded />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlarmsControls;
