const columns = (renderValueCell, renderNumberValueCell, renderTripTypeCell, renderTripPipeTypeCell, renderActions) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 185,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'type',
    headerName: 'Stripping',
    width: 185,
    renderEditCell: renderTripTypeCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'speed',
    headerName: 'Trip Speed (ft/min)',
    width: 185,
    renderEditCell: renderNumberValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'config',
    headerName: 'Tubing',
    width: 180,
    renderEditCell: renderTripPipeTypeCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 130,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  }
];
export default columns;
