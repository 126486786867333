import { Box, CardMedia, FormControl, Grid, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from '../CheckInsControls/styles';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { CALENDER_PROPS } from '../../../../utils/constants';
import { AspectRatioRounded, DirectionsRunRounded, History, FastForward, FastRewind } from '@mui/icons-material';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { syncDbwVsTarget } from '../../../../store/actions/dbwVsTarget';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import { listCheckIns } from '../../../../store/actions/checkins';
import RunDbwSyncModal from '../SyncMobileDevices/SyncMobileDevicesModal';
import Premium from '../../../../assets/images/PRO logo PMS green only.svg';
import { formatDate } from '../../../../libs/canvasjs.min';

const DbwVsTargetControls = () => {
  const classes = styles();
  const handleAspectRatio = useAspectRatio();
  const dispatch = useDispatch();
  const { preferences } = useSelector((state) => state.currentUser);
  const { startDate: initialStartDate, endDate: initialEndDate, loading, dbWDivisions, division, dbwSyncStatus } = useSelector((state) => state.dbwVsTarget);
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(initialStartDate ? new Date(initialStartDate) : null);
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(initialEndDate ? new Date(initialEndDate) : null);
  const [openRunDialog, setOpenRunDialog] = useState(false);

  useEffect(() => {
    if (initialStartDate) {
      setSelectedStartDateTime(new Date(initialStartDate));
    }
    if (initialEndDate) {
      setSelectedEnDateTime(new Date(initialEndDate));
    }
    return () => {
      dispatch({
        type: REDUX_ACTIONS.DBW_VS_TARGET_DIVISION_DATA,
        payload: 'ALL',
      });
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_DIVISION_FILTER,
        payload: 'ALL',
      });
    };
  }, [initialStartDate, initialEndDate]);

  const calculateTimeDiff = (start, end) => {
    return Math.abs(new Date(end).getTime() - new Date(start).getTime());
  };

  const handleTimeShift = (type) => {
    let updatedStartDateTime = selectedStartDateTime ? new Date(selectedStartDateTime) : null;
    let updatedEndDateTime = selectedEndDateTime ? new Date(selectedEndDateTime) : null;

    if (updatedStartDateTime && updatedEndDateTime) {
      const timeDiff = calculateTimeDiff(updatedStartDateTime, updatedEndDateTime);

      if (type === 'increase') {
        updatedStartDateTime = new Date(updatedStartDateTime.getTime() + timeDiff);
        updatedEndDateTime = new Date(updatedEndDateTime.getTime() + timeDiff);

        if (updatedStartDateTime > new Date()) {
          return;
        }
      } else {
        updatedStartDateTime = new Date(updatedStartDateTime.getTime() - timeDiff);
        updatedEndDateTime = new Date(updatedEndDateTime.getTime() - timeDiff);
      }

      setSelectedStartDateTime(updatedStartDateTime);
      setSelectedEnDateTime(updatedEndDateTime);
    }
  };

  const isForwardDisabled = () => {
    if (selectedStartDateTime && selectedEndDateTime) {
      const timeDiff = calculateTimeDiff(selectedStartDateTime, selectedEndDateTime);
      const futureStartDate = new Date(selectedStartDateTime.getTime() + timeDiff);
      return futureStartDate > new Date();
    }
    return false;
  };

  const handleSync = () => {
    dispatch(syncDbwVsTarget({ startDate: selectedStartDateTime, endDate: selectedEndDateTime }));
  };

  const handleChange = (e) => {
    dispatch({
      type: REDUX_ACTIONS.DBW_VS_TARGET_DIVISION_DATA,
      payload: e.target.value,
    });
    dispatch({
      type: REDUX_ACTIONS.CHECKINS_DIVISION_FILTER,
      payload: e.target.value,
    });
    dispatch({
      type: REDUX_ACTIONS.CHECKINS_RESET
    });

    if (e.target.value !== 'ALL') {
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_LIST_LOADING,
      });
      dispatch(listCheckIns({
        selectedStartDateTime,
        selectedEndDateTime,
        division: e.target.value,
        isCurrentPage: false
      }));
    }
  };

  const handleCloseModal = () => {
    setOpenRunDialog(false);
    dispatch({
      type: REDUX_ACTIONS.DBW_SYNC_LOADING,
      payload: ''
    });
  };

  return (
    <Grid alignItems="flex-end" justifyContent="flex-end" sx={{ marginLeft: 'auto' }}>
      <Box className={classes.container}>
      <Box className={classes.divisionContainer}>
          <Typography variant='body2' className={classes.divisionText}>Division</Typography>
          <FormControl variant='outlined' sx={{ minWidth: '180px' }}>
            <Select
              labelId="simple-select-standard-label"
              id="simple-select-standard"
              value={division}
              onChange={handleChange}
              size='small'
              sx={{
                '& .MuiSelect-select': {
                  padding: '14px',
                },
              }}
              disabled={loading}
            >
              <MenuItem
                  key={'ALL'}
                  value={'ALL'}
                >
                  ALL
                </MenuItem>
              {dbWDivisions?.sort().map((item) => (
                <MenuItem
                  key={`item_${item}`}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      <Box className={classes.dateSwiftContainer}>
           <IconButton onClick={() => handleTimeShift('decrease')} disabled={loading}>
             <FastRewind />
          </IconButton>
          <IconButton onClick={() => handleTimeShift('increase')} disabled={loading || isForwardDisabled()}>
            <FastForward />
          </IconButton>
      </Box>
          <Box className={classes.dateContainer}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              {...CALENDER_PROPS}
              disableFuture
              label="Start Date"
              value={selectedStartDateTime}
              onChange={(date) => setSelectedStartDateTime(date)}
              className={classes.dateTimePicker}
              textField={(params) => <TextField {...params} />}
              disabled={loading}
            />
            <DateTimePicker
              label="End Date"
              {...CALENDER_PROPS}
              value={selectedEndDateTime}
              onChange={(date) => setSelectedEnDateTime(date)}
              className={classes.dateTimePicker}
              minDateTime={selectedStartDateTime}
              disableFuture
              textField={(params) => <TextField {...params} />}
              disabled={loading}
            />
            </LocalizationProvider>
          </Box>
        <Box className={classes.runActionContainer}>
          <IconButton
            disabled={loading}
            size="large"
            onClick={() => {
              setSelectedStartDateTime(new Date(Date.now() - 8 * 7 * 24 * 60 * 60 * 1000));
              setSelectedEnDateTime(new Date());
            }}
          >
            <History />
          </IconButton>
        </Box>
        <Box className={classes.runActionContainer}>
          <IconButton
            disabled={loading || (selectedStartDateTime === null && selectedEndDateTime === null) || selectedEndDateTime > new Date()}
            size="large"
            onClick={() => {
              setOpenRunDialog(true);
            }}
          >
            <DirectionsRunRounded />
          </IconButton>
        </Box>
        <Box className={classes.aspectRatioContainer}>
          <IconButton
            edge="end"
            onClick={() => {
              handleAspectRatio(!preferences.aspectChanged);
            }}
          >
            <AspectRatioRounded />
          </IconButton>
        </Box>
      </Box>
      {openRunDialog
        ? (
          <RunDbwSyncModal
            open={openRunDialog}
            onClose={handleCloseModal}
            handleClose={handleCloseModal}
            cta2Label={'Run'}
            cta2Disabled={loading}
            cta2Action={handleSync}
            showCta2={dbwSyncStatus !== 'Report complete'}
            showDateTimeRange={false}
          >
            <CardMedia
              component="img"
              image={Premium}
              className={classes.logo}
            />

            <Typography variant="h4" gutterBottom component="div" className={classes.title}>
              Run Report for {formatDate(selectedStartDateTime)} - {formatDate(selectedEndDateTime)}
            </Typography>

            {dbwSyncStatus !== ''
              ? <Typography variant="h5" gutterBottom component="div" sx={{ textAlign: 'center', mt: 4 }}>
                {dbwSyncStatus}
              </Typography>
              : <div style={{
                height: 40,
              }}></div>
            }
          </RunDbwSyncModal>
          )
        : null}
    </Grid>
  );
};

export default DbwVsTargetControls;
