import React, { useEffect, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles';
import { createNewJob, listJobs, listSignalsData, updateActiveJob, updateFavoriteJob, updateJobDetails } from '../../store/actions/jobs';
import { createActuatorReport } from '../../store/actions/actuatorReport';
import pin from '../../assets/images/pin.png';
import pinGreen from '../../assets/images/pin_green.png';
import pinRed from '../../assets/images/pin_red.png';
import pinAmber from '../../assets/images/pin_amber.png';
import { Icon } from 'leaflet';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import AlarmsModalDialog from '../ui/AlarmsModalDialog';
import { Box, Divider, Grid, IconButton, Switch } from '@mui/material';
import { renderCell } from '../ui/Datagrid/table_config';
import {
  AnalyticsRounded,
  NotificationsNoneRounded,
  SettingsSuggestRounded,
  WidgetsRounded,
  RoomRounded,
  SummarizeRounded,
  BuildRounded,
  SaveRounded,
  DirectionsRunRounded,
  Star,
  StarBorder,
} from '@mui/icons-material';
import AssignAssetsModalDialog from '../ui/AssignAssetsModalDialog';
import JobStatusModalDialog from '../ui/JobStatusModalDialog';
import {
  DRILLING_FILTERS,
  JOBS_SYSTEM_STATUS,
  USER_TYPE,
} from '../../utils/constants';
import { ASSET_DATA_TYPES, MAP_TYPES, REDUX_ACTIONS } from '../../shared/config/constants';
import FilterCarousel from '../filterCarousel';
import FilterCarouselItem from '../filterCarouselItem';
import CustomModal from '../customModal';
import PropTypes from 'prop-types';
import { gridFilteredSortedRowEntriesSelector, useGridApiRef } from '@mui/x-data-grid-pro';
import MapComponent from '../map';
import JobsCarouselReportCard from '../jobsCarouselReportCard';
import { listWells } from '../../store/actions/wells';
import SelectJobRigAutoComplete from '../ui/SelectJobRigAutocompleteCell';
import HydraulicsModal from '../ui/HydraulicsModalDialog';
import SelectJobWellAutoComplete from '../ui/SelectJobWellAutocomplete';
import { enqueueSnackbar } from '../../store/actions/snackbar';
import store from '../../store/store';
import { useAuth } from '../../hooks/useAuth';
import { blue } from '@mui/material/colors';

const pins = {
  default: pin,
  green: pinGreen,
  red: pinRed,
  amber: pinAmber,
};

const Jobs = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const jobs = useSelector((state) => state.jobs);
  const [isAssetsDialogOpen, setIsAssetsDialogOpen] = useState(false);
  const [isJobStatusDialogOpen, setIsJobStatusDialogOpen] = useState(false);
  const [isHydraulicsDialogOpen, setIsHydraulicsDialogOpen] = useState(false);
  const [currentJob, setCurrentJob] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [drillingLink, setDrillingLink] = useState('');
  const [alarmModalOpen, setAlarmModalOpen] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [gmapRowId, setGmapRowId] = useState(null);
  // Report Handlign
  const [reportJobId, setReportJobId] = useState('');
  const [reportStartDate, setReportStartDate] = useState('');
  const [reportEndDate, setReportEndDate] = useState('');
  const mapType = process.env.REACT_APP_JOBS_PAGE_MAP;
  const apiRef = useGridApiRef();
  const [jobMarker, setJobMarker] = useState([]);
  const [initialMarker, setInitialMarker] = useState(true);
  const [actuatorDataSignals, setActuatorDataSignals] = useState([]);
  const [drillingDataSignals, setDrillingDataSignals] = useState([]);
  const [reportModalTitle, setReportModalTitle] = useState('');
  const wells = useSelector((state) => state.wells);
  const [rigOption, setRigOption] = useState([]);
  const [wellOption, setWellOption] = useState([]);
  const pauseJobRefresh = useRef(null);
  const [selectedRig, setSelectedRig] = useState('');
  const [dropdownEditable, setDropdownEditable] = useState(false);
  const [invalidRows, setInvalidRows] = useState({});
  const { user } = useAuth();

  useEffect(() => {
    if (!wells.loading || !jobs.loading) {
      dispatch(listWells());
      dispatch(listJobs());
    }
  }, []);

  useEffect(() => {
    if (jobs?.data[0]?.Rig === '') {
      pauseJobRefresh.current = true;
      const id = jobs?.data[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 0
        });
        apiRef.current.setCellMode(id, 'Rig', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'Rig');
        }, 300);
      }, 300);
      apiRef.current.setPage(0);
    }
  }, [jobs.data]);

  useEffect(() => {
    if (jobs?.data?.length && wells?.data?.length) {
      const assignedWellsInfoIds = new Set(jobs.data.map(job => job.wellsInfoId));
      const rigMap = new Map();
      for (const well of wells.data) {
        const wellId = well._id;
        const rigName = well?.rig?.toLowerCase();
        if (!assignedWellsInfoIds.has(wellId) && !rigMap.has(rigName)) {
          rigMap.set(rigName, well);
        }
      }
      const uniqueRigData = Array.from(rigMap.values());
      setRigOption(uniqueRigData);
    } else {
      setRigOption([]);
    }
  }, [jobs?.data, wells?.data]);

  useEffect(() => {
    if (!jobs?.data || !wells?.data || !selectedRig) {
      setWellOption([]);
      return;
    }
    const jobsWellsInfoIds = new Set(jobs.data.map(job => job.wellsInfoId));
    const availableWells = wells.data.filter(well => {
      const isMatchingRig = well.rig.toLowerCase() === selectedRig.toLowerCase();
      const isNotInJobs = !jobsWellsInfoIds.has(well._id);
      return isMatchingRig && isNotInJobs;
    });
    setWellOption(availableWells);
  }, [selectedRig, jobs?.data, wells?.data]);

  useEffect(() => {
    if (!jobs.loading && jobs.data.length) {
      setColumnsList(columns(
        true,
        renderCell,
        renderDataCell,
        renderReportsCell,
        renderAssetsCell,
        renderStatusCell,
        renderAlarmsCell,
        renderTruFlowCell,
        renderJobsRigEditCell,
        renderJobsWellEditCell,
        dropdownEditable,
        renderHydraulicCell,
        renderFavoriteEditCell,
        renderActions,
        user
      ));
    }
  }, [jobs.loading, rigOption, wellOption, dropdownEditable]);

  React.useEffect(() => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    setReportStartDate(startDate);
    setReportEndDate(new Date());
  }, [showReportModal]);

  useEffect(() => {
    if ((jobs.marker_data.length && initialMarker)) {
      setJobMarker(jobs.marker_data);
      setInitialMarker(false);
    }
    if (!jobs.markerFilter && !initialMarker) {
      setJobMarker(jobs.marker_data);
    }
  }, [jobs.marker_data]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!pauseJobRefresh.current) {
        dispatch(listJobs());
      }
    }, 120000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (jobs?.currentJob?.actuatorDrillingSignalData?.actuatorKeys?.length) {
      setActuatorDataSignals(jobs?.currentJob?.actuatorDrillingSignalData?.actuatorKeys);
    }
    if (jobs?.currentJob?.actuatorDrillingSignalData?.drillingKeys?.length) {
      setDrillingDataSignals(jobs?.currentJob?.actuatorDrillingSignalData?.drillingKeys);
    }
  }, [jobs?.currentJob?.actuatorDrillingSignalData]);

  const showAssetsDialog = (job) => {
    setCurrentJob(job);
    setIsAssetsDialogOpen(true);
  };

  const showJobStatusDialog = (job) => {
    setCurrentJob(job);
    setIsJobStatusDialogOpen(true);
  };

  const showHydraulicsDialog = (job) => {
    setCurrentJob(job);
    setIsHydraulicsDialogOpen(true);
  };

  const handleNavigate = (assetDataType, jobId, rigName) => {
    let pageTitle = '';

    if (rigName) {
      pageTitle = `&pageTitle=${encodeURIComponent(rigName)}&filterId=2`;
    }
    window.open(
      `/Data?assetDataType=${assetDataType}_${jobId}${pageTitle}`,
      '_blank'
    );
  };

  const drillingModal = (assetDataType, jobId, rigName) => {
    let pageTitle = '';

    if (rigName) {
      pageTitle = `&pageTitle=${encodeURIComponent(rigName)}`;
    }
    setDrillingLink(
      `/Data?assetDataType=${assetDataType}_${jobId}${pageTitle}`
    );
    setShowModal(true);
  };

  const hideDrillingModal = () => {
    setDrillingLink('');
    setShowModal(false);
  };

  const handleCardItemClick = (cardIndex) => {
    setShowModal(false);
    setDrillingLink(drillingLink);
    window.open(
      drillingLink + `&filterId=${DRILLING_FILTERS[cardIndex].id}`,
      '_blank'
    );
  };

  const reportModal = (jobId) => {
    setReportJobId(jobId);
    setShowReportModal(true);
    dispatch(listSignalsData(jobId));
  };

  const hideReportModal = () => {
    setReportJobId('');
    setReportStartDate('');
    setReportEndDate('');
    setShowReportModal(false);
  };

  const handleReportPrintIconClick = (itemId) => {
    if (itemId === 0) {
      if (reportJobId !== '' && reportStartDate !== '' && reportEndDate !== '') {
        dispatch(
          createActuatorReport(reportStartDate, reportEndDate, reportJobId)
        );
      }
    }
  };

  const handleReportDateChange = (startDate, endDate) => {
    setReportStartDate(startDate);
    setReportEndDate(endDate);
  };

  const createReport = () => {
    if (reportJobId !== '' && reportStartDate !== '' && reportEndDate !== '') {
      dispatch(
        createActuatorReport(reportStartDate, reportEndDate, reportJobId)
      );
    }
  };

  const handleAlarmsModal = (overallStatus) => {
    if (overallStatus > 0) {
      setAlarmModalOpen(true);
    }
  };

  const renderDataCell = (props) => {
    const data = props.row.data;
    const jobId = props.row._id;
    const rigName = props.row.Rig;
    return (
      <Grid container justifyContent="center">
        {
          /* prettier-ignore */
          data === 1
            ? (
              <IconButton
                variant="solid"
                key="drill_data"
                onClick={() =>
                  drillingModal(ASSET_DATA_TYPES.DRILLINGDATA, jobId, rigName)
                }
              >
                <AnalyticsRounded />
              </IconButton>
              )
            : data === 2
              ? (
                <IconButton
                  key="actuator_data"
                  variant="solid"
                  onClick={() =>
                    handleNavigate(ASSET_DATA_TYPES.ACTUATORDATA, jobId, rigName)
                  }
                >
                  <SettingsSuggestRounded />
                </IconButton>
                )
              : data === 3
                ? (
                  <>
                    <IconButton
                      key="actuator_data"
                      variant="solid"
                      onClick={() =>
                        handleNavigate(ASSET_DATA_TYPES.ACTUATORDATA, jobId, rigName)
                      }
                    >
                      <SettingsSuggestRounded />
                    </IconButton>
                    <IconButton
                      variant="solid"
                      key="drill_data"
                      onClick={() =>
                        drillingModal(ASSET_DATA_TYPES.DRILLINGDATA, jobId, rigName)
                      }
                    >
                      <AnalyticsRounded />
                    </IconButton>
                  </>
                  )
                : null
        }
      </Grid>
    );
  };

  const renderHydraulicCell = (_props) => {
    return (
      <>
        <Grid container justifyContent="center">
         { _props.row.hydraulicsInputs && <IconButton
            variant="solid"
            key="hydraulics"
            onClick={ () => {
              showHydraulicsDialog();
            }}
          >
            <DirectionsRunRounded />
          </IconButton>}
        </Grid>
      </>
    );
  };

  const renderReportsCell = (_props) => {
    const data = _props.row.data;
    const jobId = _props.row._id;
    return (
      <Grid container justifyContent="center">
        {
          /* prettier-ignore */
          data === 2
            ? (
              <IconButton
                key="actuator_data"
                variant="solid"
                onClick={() => {
                  reportModal(jobId);
                  setReportModalTitle(_props?.row?.Rig || '');
                }}
              >
                <SummarizeRounded />
              </IconButton>
              )
            : data === 3
              ? (
                <IconButton
                  key="actuator_data"
                  variant="solid"
                  onClick={() => {
                    reportModal(jobId);
                    setReportModalTitle(_props?.row?.Rig || '');
                  }}
                >
                  <SummarizeRounded />
                </IconButton>
                )
              : null
        }
      </Grid>
    );
  };

  const renderAssetsCell = (props) => {
    if (props.row.systemStatus === null) return;

    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          onClick={(_evt) => {
            showAssetsDialog(props.row);
          }}
        >
          <WidgetsRounded />
        </IconButton>
      </Grid>
    );
  };

  const updateFavourite = (row, value) => {
    const { jobs } = store.getState();
    const updatedProperties = jobs.data.map(item => {
      if (item._id === row?._id) {
        return {
          ...item,
          isFavorite: value
        };
      }
      return item;
    });
    dispatch({
      type: REDUX_ACTIONS.JOBS_LIST_SUCCESS,
      payload: {
        data: updatedProperties,
        paginatedElements: updatedProperties.length,
      },
    });
  };

  const updateActiveJobRow = (row, value) => {
    const { jobs } = store.getState();
    const updatedProperties = jobs.data.map(item => {
      if (item._id === row?._id) {
        return {
          ...item,
          active: value
        };
      }
      return item;
    });
    dispatch({
      type: REDUX_ACTIONS.JOBS_LIST_SUCCESS,
      payload: {
        data: updatedProperties,
        paginatedElements: updatedProperties.length,
      },
    });
  };

  const renderFavoriteEditCell = (props) => {
    return (
    <>
        <Grid container justifyContent="center">
         { props.row.isFavorite
           ? <IconButton
            variant="solid"
            key="jobsFav"
            disabled={false}
            onClick={ () => {
              if (props?.row?.isNewRow) {
                updateFavourite(props?.row, false);
              } else {
                const body = {
                  type: 'remove',
                  inputId: props?.row?._id
                };
                dispatch(updateFavoriteJob(body));
              }
            }}
          >
            <Star/>
          </IconButton>
           : <IconButton
          disabled={false}
          variant="solid"
          key="jobFav"
          onClick={ () => {
            if (props?.row?.isNewRow) {
              updateFavourite(props?.row, true);
            } else {
              const body = {
                type: 'add',
                inputId: props?.row?._id
              };
              dispatch(updateFavoriteJob(body));
            }
          }}
        >
          <StarBorder/>
        </IconButton>
          }
        </Grid>
    </>
    );
  };

  const getPinColor = (status) => {
    switch (status) {
      case JOBS_SYSTEM_STATUS.NONE:
        return 'green';
      case JOBS_SYSTEM_STATUS.FAULTS_ONLY:
      case JOBS_SYSTEM_STATUS.BOTH:
        return 'red';
      case JOBS_SYSTEM_STATUS.WARNINGS_ONLY:
        return 'amber';
      default:
        return 'default';
    }
  };

  const getGoogleMapPin = (status) => {
    const pinColor = getPinColor(status);
    const pinIcon = pinColor ? pins[pinColor] : pins.default;
    return {
      url: pinIcon,
      // eslint-disable-next-line no-undef
      scaledSize: new google.maps.Size(15, 25),
      // eslint-disable-next-line no-undef
      size: new google.maps.Size(15, 25)
    };
  };

  const getMapPin = (status) => {
    const pinColor = getPinColor(status);
    const pinIcon = pinColor ? pins[pinColor] : pins.default;

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: [15, 25],
        popupAnchor: [0, -5],
      })
    );
  };

  const renderStatusCell = (_props) => {
    const status = _props.row.systemStatus;
    const pinColor = getPinColor(status);

    if (status === null) return;

    const noActionPin = [
      JOBS_SYSTEM_STATUS.NO_ACTUATOR_DATA,
      JOBS_SYSTEM_STATUS.NONE,
    ].includes(status);

    return (
      pinColor && (
        <Grid container justifyContent="center">
          <IconButton
            className={noActionPin ? classes.noActionPin : ''}
            key="systemStatus"
            variant="solid"
            onClick={({ row: job }) => {
              if (noActionPin) {
                return;
              }

              showJobStatusDialog(job);
            }}
          >
            <RoomRounded color={`${pinColor}Status`} />
          </IconButton>
        </Grid>
      )
    );
  };

  const renderAlarmsCell = (_props) => {
    const data = _props.row.data;
    return (
      <Grid container justifyContent="center">
        {
          /* prettier-ignore */
          data === 2
            ? (
              <IconButton key="actuator_data" variant="solid" onClick={() => {
                handleAlarmsModal(_props.row.alarms.overallStatus);
              }}>
                <NotificationsNoneRounded color={`alarm${_props.row.alarms.overallStatus}`} />
              </IconButton>
              )
            : data === 3
              ? (
                <IconButton key="actuator_data" variant="solid" onClick={() => {
                  handleAlarmsModal(_props.row.alarms.overallStatus);
                }}>
                  <NotificationsNoneRounded color={`alarm${_props.row.alarms.overallStatus}`} />
                </IconButton>
                )
              : null
        }
      </Grid>
    );
  };

  const renderTruFlowCell = (_props) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          onClick={() => {
            window.open(`./TruFlowSettings?ringName=${_props.row.Rig}`, '_blank');
          }}
        >
          <BuildRounded />
        </IconButton>
      </Grid>
    );
  };

  const renderJobsWellEditCell = (params) => {
    pauseJobRefresh.current = true;
    return <SelectJobWellAutoComplete closeCellAfterSelect={true} {...params} data={wellOption} />;
  };

  const renderJobsRigEditCell = (params) => {
    pauseJobRefresh.current = true;
    return <SelectJobRigAutoComplete closeCellAfterSelect={true} {...params} data={rigOption} setSelectedRig={setSelectedRig} />;
  };

  const checkJobsData = (row) => {
    const invalidFields = [];
    if (row?.Rig === undefined || row?.Rig === null || row?.Rig === '') invalidFields.push('Rig');
    if (row?.Well === undefined || row?.Well === null || row?.Well === '') invalidFields.push('Well');
    if (invalidFields.length === 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));

      return true;
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          `Please choose the following: ${invalidFields.join(', ')}`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const handleSave = (row) => {
    if (checkJobsData(row)) {
      pauseJobRefresh.current = null;
      if (row?.isNewRow) {
        dispatch(createNewJob(row));
      } else {
        dispatch(updateJobDetails(row));
      }
    }
  };

  const renderActions = ({
    row
  }) => {
    return (
      <Grid container justifyContent="center">
         {(user.type === USER_TYPE.PREMIUM_ADMIN || user.type === USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN) && <Box>
         <Switch
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: blue[300],
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: blue[300],
            },
          }}
          checked={row?.active}
          onChange={(event) => {
            if (row?.isNewRow) {
              updateActiveJobRow(row, event.target.checked);
            } else {
              updateActiveJobRow(row, event.target.checked);
              const body = {
                active: event.target.checked,
                _id: row?._id
              };
              dispatch(updateActiveJob(body));
            }
          }}
          inputProps={{ 'aria-label': 'controlled' }}
          color="default"/>
         </Box> }
        <IconButton
          variant="solid"
          onClick={() => {
            handleSave(row);
          }}
        >
          <SaveRounded />
        </IconButton>
      </Grid>
    );
  };

  const getAssetTypesLabel = (assets) => assets.map((a) => a.type).join(', ');
  const [columnsList, setColumnsList] = useState(columns(
    true,
    renderCell,
    renderDataCell,
    renderReportsCell,
    renderAssetsCell,
    renderStatusCell,
    renderAlarmsCell,
    renderTruFlowCell,
    renderJobsRigEditCell,
    renderJobsWellEditCell,
    dropdownEditable,
    renderHydraulicCell,
    renderFavoriteEditCell,
    renderActions,
    user
  ));
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const handleVisibiltyModel = (newModel) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <Grid container justifyContent="center">
      <MapComponent
        mapType={mapType}
        mapData={jobMarker}
        markerData={jobMarker}
        data={jobs}
        latitudeField={'Latitude'}
        longitudeField={'Longitude'}
        mapPinField={'systemStatus'}
        popUpLabel={['Rig']}
        getAssetType={'assets'}
        getAssetTypesLabel={getAssetTypesLabel}
        getGoogleMapPin={getGoogleMapPin}
        getMapPin={getMapPin}
        gmapRowId={gmapRowId}
        rowId={rowId}
        mapPinField1={'systemStatus'}
      />
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={jobs.data}
          columns={columnsList}
          loading={jobs.loading}
          onRowClick={({ row: job }) => {
            if (mapType === MAP_TYPES.GOOGLEMAP) {
              setGmapRowId(job._id);
            } else {
              setRowId(job);
            }
            setCurrentJob(job);
          }}
          getRowClassName={(params) => {
            return invalidRows[params.id] ? classes.invalidRow : '';
          }}
          onRowDoubleClick={({ row: job }) => {
            setSelectedRig(job?.Rig);
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            handleVisibiltyModel(newModel);
          }}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            },
            '.MuiDataGrid-cell': {
              fontSize: 'medium',
              fontWeight: 'bold !important'
            },
          }}
          onFilterModelChange={() => {
            setTimeout(() => {
              const filterData = gridFilteredSortedRowEntriesSelector(apiRef).map((item) => item.model);
              if (filterData) {
                setJobMarker(filterData);
                dispatch({
                  type: REDUX_ACTIONS.JOBS_MARKER_FILTER,
                  payload: {
                    data: filterData,
                  }
                });
              }
            }, 300);
          }}
          onSelectionModelChange={(ids) => {
            const isdropdownEditable = apiRef?.current.getSelectedRows().get(ids[0])?.isNewRow || false;
            setDropdownEditable(isdropdownEditable);
          }}
          editMode="cell"
          toolbar={{
            options: {
              columns: true,
              filters: true,
              export: false,
            },
          }}
        />
      </Grid>
      <AlarmsModalDialog
        isOpen={alarmModalOpen}
        onCloseClick={() => setAlarmModalOpen(false)}
        selectedJob={currentJob}
      />
      <AssignAssetsModalDialog
        isOpen={isAssetsDialogOpen}
        onActionClick={() => { }}
        onCloseClick={() => {
          // TODO reset diaog content if required
          setIsAssetsDialogOpen(false);
        }}
        selectedJob={currentJob}
      />
      <JobStatusModalDialog
        isOpen={isJobStatusDialogOpen}
        onCloseClick={() => setIsJobStatusDialogOpen(false)}
        selectedJob={currentJob}
      />
      <CustomModal open={showModal} onClose={hideDrillingModal}>
        <FilterCarousel
          carrouselDataSource={DRILLING_FILTERS}
          handleCardItemClick={handleCardItemClick}
        >
          <FilterCarouselItem />
        </FilterCarousel>
      </CustomModal>

      <CustomModal
        title={reportModalTitle}
        onDateTimeRangeChange={handleReportDateChange}
        open={showReportModal}
        onClose={hideReportModal}
        cta1Label={'Close'}
        showDateTimeRange={true}
        showForwardRewindIcon={true}
        showCta2={false}
        cta2Label={'Report'}
        cta2Action={createReport}
      >
        <Divider className={classes.dividerStyle} />
        <JobsCarouselReportCard
          reportJobId={reportJobId}
          drillingDataSignals={drillingDataSignals}
          actuatorDataSignals={actuatorDataSignals}
          reportEndDate={reportEndDate}
          reportStartDate={reportStartDate}
          handleReportPrintIconClick={handleReportPrintIconClick} />
      </CustomModal>
      <HydraulicsModal
       isOpen={isHydraulicsDialogOpen}
       onCloseClick={() => setIsHydraulicsDialogOpen(false)}
       selectedJob={currentJob}
      />
    </Grid>
  );
};

Jobs.propTypes = {
  row: PropTypes.object,
};

export default Jobs;
