import { USER_TYPE } from '../../../utils/constants';
const columnsUnAssigned = (renderCell, renderActionCell, renderValueCell, userType) => [
  {
    field: 'SN',
    headerName: 'SN',
    renderCell,
    renderEditCell: renderValueCell,
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
  {
    field: 'Type',
    headerName: 'Type',
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'Action',
    headerName: 'Action',
    renderCell: renderActionCell,
    headerAlign: 'center',
    // eslint-disable-next-line
    hide: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN ? false : true,
    align: 'center',
    width: 180
  }
];

const columnsAssigned = (renderCell) => [
  {
    field: 'SN',
    headerName: 'SN',
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'Type',
    headerName: 'Type',
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
];
export { columnsUnAssigned, columnsAssigned };
