import { AspectRatioRounded, SyncRounded } from '@mui/icons-material';
import { Box, CardMedia, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { useState } from 'react';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import Premium from '../../../../assets/images/PRO logo PMS green only.svg';
import SyncMobileDevicesModal from './../../ControlPanel/SyncMobileDevices/SyncMobileDevicesModal/index';
import styles from './styles';
import { syncEnverusRigs } from '../../../../store/actions/rigs';
import { useAuth } from '../../../../hooks/useAuth';
import { USER_TYPE } from '../../../../utils/constants';

const usersWithSyncAccess = [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN];

const RigsControls = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleAspectRatio = useAspectRatio();
  const { preferences } = useSelector((state) => state.currentUser);
  const rigs = useSelector((state) => state.rigs);
  const classes = styles();
  const { user } = useAuth();

  const handleOpenModal = () => {
    setOpen(true);
    dispatch({
      type: REDUX_ACTIONS.RIGS_SYNC_IDLE,
      payload: {
        message: '',
      },
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
    dispatch({
      type: REDUX_ACTIONS.RIGS_SYNC_IDLE,
      payload: {
        message: '',
      },
    });
  };

  const handleSyncRigs = () => {
    dispatch(syncEnverusRigs());
  };

  return (
    <Box>
      <Box className={classes.container}>
      <Box>
      {usersWithSyncAccess.includes(user.type) && <IconButton
          edge="end"
          onClick={() => {
            handleOpenModal();
          }}
          sx={{ marginRight: '1%' }}
          disabled={rigs.loading === true}
        >
          <SyncRounded />
        </IconButton>}
      </Box>
      <Box>
      <IconButton
          edge="end"
          onClick={() => {
            handleAspectRatio(!preferences.aspectChanged);
          }}
          sx={{ marginRight: '1%' }}
        >
          <AspectRatioRounded />
        </IconButton>
      </Box>
      </Box>
      <SyncMobileDevicesModal
        open={open}
        onClose={handleCloseModal}
        handleClose={handleCloseModal}
        cta2Label={'Sync'}
        cta2Disabled={rigs.loadingRigSync}
        cta2Action={handleSyncRigs}
        showCta2={rigs.syncResponseMessage !== 'Sync Completed'}
        showDateTimeRange={false}
      >
        <CardMedia
          component="img"
          image={Premium}
          className={classes.logo}
        />

        <Typography variant="h3" gutterBottom component="div" className={classes.title}>
          Sync Rigs?
        </Typography>

        { rigs.syncResponseMessage !== ''
          ? <Typography variant="h5" gutterBottom component="div" className={classes.statusMessage}>
            {rigs.syncResponseMessage}
          </Typography>
          : <div style={{
            height: 40,
          }}></div>
        }
      </SyncMobileDevicesModal>
    </Box>
  );
};

export default RigsControls;
