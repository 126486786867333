import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { Favorite, FavoriteBorder, LiveHelp } from '@mui/icons-material';
import LiveHelpSalesReps from './LiveHelpSalesReps';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from '@mui/material';
import { REDUX_ACTIONS } from '../../../shared/config/constants';

const LiveHelpButton = (props) => {
  const dispatch = useDispatch();
  const [showSalesReps, setShowSalesReps] = useState({
    open: false,
    type: null
  });
  const { showFavorites, partsFavorites } = useSelector((state) => state.parts);

  const handleClick = () => {
    setShowSalesReps({
      open: true,
      type: props?.type
    });
  };

  return (
    <>
      <Box
        sx={props?.style ? { ...props?.style } : { position: 'fixed', bottom: 16, left: 16, zIndex: 1000 }}
      >
        <Fab
          aria-label="live-help"
          size={props?.isMobile ? 'medium' : 'large'}
          onClick={handleClick}
          sx={{
            backgroundColor: '#666',
            '&:hover': {
              backgroundColor: 'gray',
            }
          }}>
          <LiveHelp sx={{ fontSize: props?.isMobile ? '35px' : '40px', color: '#FFF' }} />
        </Fab>
        <Fab
          onClick={() => {
            dispatch({
              type: REDUX_ACTIONS.PARTS_FAVORITES_FILTER,
            });
          }}
          aria-label="part-favorites"
          size={props?.isMobile ? 'medium' : 'large'}
          sx={{
            backgroundColor: showFavorites ? '#FFF' : '#666',
            ml: 1,
            '&:hover': {
              backgroundColor: showFavorites ? '#FFF' : '#666',
            },
            display: !partsFavorites.length && 'none'
          }}>
          <Badge
            badgeContent={partsFavorites.length}
            color="error"
            style={{
              borderRadius: '25px',
            }}
          >
            {showFavorites ? <Favorite sx={{ fontSize: props?.isMobile ? '35px' : '40px', color: '#666', }} /> : <FavoriteBorder sx={{ fontSize: props?.isMobile ? '35px' : '40px', color: '#FFF' }} />}
          </Badge>
        </Fab>
      </Box>
      {showSalesReps.open && (
        <LiveHelpSalesReps
          open={showSalesReps.open}
          handleClose={() => {
            setShowSalesReps({
              open: false,
              type: null
            });
          }}
          type={showSalesReps.type}
        />
      )}
    </>
  );
};

LiveHelpButton.propTypes = {
  style: PropTypes.object,
  isMobile: PropTypes.bool,
  type: PropTypes.string
};

export default LiveHelpButton;
