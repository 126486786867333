/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Box, Button, DialogActions, FilledInput, FormControl, Grid, IconButton } from '@mui/material';
import { CancelPresentationRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import styles from './styles';
import ModalDialog from '../../../ui/ModalDialog';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from '../../../../store/actions/snackbar';
import { downloadSSLCertificate } from '../../../../store/actions/jobs';
const SSLGenerationModal = ({
  handleClose,
  open,
}) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [sn, setSN] = useState('');
  const jobData = useSelector((state) => state.jobs);

  const getModalTitle = () => {
    return (
            <>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        {'Generate SSL Certificate'}
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={handleClose}
                        >
                            <CancelPresentationRounded fontSize='large' />
                        </IconButton>
                    </Grid>
                </Grid>
            </>
    );
  };

  return (
        <ModalDialog
            title={getModalTitle()}
            isOpen={open}
            onClose={handleClose}
            maxWidth='xl'
            dialogClass={classes.dialogModal}
        >
            <Grid container item justifyContent='center' margin='4% 0'>
                <Box>
                    <FormControl>
                        <FilledInput
                            className={classes.searchBar}
                            id="outlined-adornment-search"
                            type="text"
                            placeholder='Cert SN'
                            label="Search"
                            value={sn}
                            onChange={(e) => {
                              setSN(e.target.value);
                            }}
                        />
                    </FormControl>
                </Box>
            </Grid>
            <Grid container item justifyContent='center' margin='4% 0'>
                <Box>
                    <DialogActions>
                        <Button
                            disabled={jobData?.sslGenerate === 'loading'}
                            style={{ backgroundColor: '#444', marginRight: '30px', width: '100px' }}
                            onClick={() => {
                              if (/^\d+$/.test(sn)) {
                                dispatch(downloadSSLCertificate(Number(sn)));
                                setSN('');
                              } else {
                                dispatch(
                                  enqueueSnackbar(
                                    'Enter only integer value',
                                    'error',
                                    new Date().getTime() + Math.random()
                                  )
                                );
                              }
                            }}
                        >
                            Generate
                        </Button>
                    </DialogActions>
                </Box>
            </Grid>
        </ModalDialog>
  );
};
SSLGenerationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SSLGenerationModal;
