import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Box,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  FilledInput,
} from '@mui/material';

import styles from './styles';

import Premium from '../../assets/images/PRO logo PMS green only.svg';
import clsx from 'clsx';
import { useAuth } from '../../hooks/useAuth';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from '../../utils/isMobile';
import MobileAuthentication from '../mobile/authentication';
import ButtonComponent from '../Button';

const Login = () => {
  const { onLogin } = useAuth();
  const user = useSelector((state) => state.currentUser);
  const classes = styles();

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    let mounted = false;
    if (!mounted && user.logged) {
      mounted = true;
      if (!location.state) {
        navigate('/');
      } else {
        navigate(location.state.from);
      }
    }
  }, [user.logged]);

  const validateForm = () => {
    if (emailAddress.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.LOGIN_USER_FAILED,
        payload: { message: '* Email Address is required' },
      });

      return false;
    }
    if (password.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.LOGIN_USER_FAILED,
        payload: { message: '* Password is required' },
      });

      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      onLogin({ emailAddress, password });
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      className={classes.mainContainer}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        justifyContent="center"
        sx={{ height: isMobile() ? '100%' : 'none' }}
      >
        <Grid
          item
          container
          xs={isMobile() ? 12 : 6}
          margin="auto"
          justifyContent="center"
          className={isMobile() ? '' : classes.loginContainer}
        >
          <Grid item container xs={12} justifyContent="center">
            <Box
              component="img"
              alt="Logo"
              src={Premium}
              className={classes.logo}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={clsx(classes.small_banner)} variant="h6">
              {`Last Update: ${isMobile() ? '1.0' : '6d49915'}`}
            </Typography>
          </Grid>
          { isMobile()
            ? <MobileAuthentication
                setEmailAddress={setEmailAddress}
                password={password}
                showPassword={showPassword}
                setPassword={setPassword}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                 />
            : <Grid item xs={12} padding={1} className={classes.formContainer}>
            <Typography color="error">{user.errorMessage}</Typography>

            <FormControl
              sx={{
                bgcolor: 'transparent',
              }}
              fullWidth
              variant="filled"
              onChange={(value) => {
                setEmailAddress(value.target.value);
              }}
            >
              <InputLabel htmlFor="outlined-adornment-email">
                Email Address
              </InputLabel>
              <FilledInput
                className={classes.emailInput}
                id="outlined-adornment-email"
                type="email"
                label="Email Address"
                autoFocus
              />
            </FormControl>
            <FormControl
              sx={{
                bgcolor: 'transparent',
              }}
              fullWidth
              variant="filled"
              value={password}
              onChange={(value) => {
                setPassword(value.target.value);
              }}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <FilledInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                disableUnderline={true}
                className={classes.passwordInput}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Grid>}
          <Grid item xs={12}>
            <Typography className={clsx(classes.small_banner)} variant="h6">
              <Box className={classes.linksContainer}>
                <Link
                  to="/ResetPasswordSendEmail"
                  className={classes.actionLink}
                >
                  Reset Password
                </Link>
                <Link
                  to="/SignUp"
                  className={classes.actionLink}
                >
                  Sign Up
                </Link>
                <Link
                  to="/PrivacyPolicy"
                  className={classes.actionLink}
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </Box>
            </Typography>
          </Grid>
          {/* <Grid item xs={12} textAlign="center">
            <Typography className={clsx(classes.small_banner)} variant="h6">
              <Link
                  to="/SignUp"
                  className={classes.resetPasswordLink}
                >
                  Sign Up
                </Link>
            </Typography>
          </Grid> */}
          <Grid item xs={isMobile() ? 10 : 6}
            className={isMobile() ? classes.loginButton : ''}
          >
            <ButtonComponent
              className={classes.button}
              label="Sign In"
              fullWidth
              disabled={user.loading}
              type="submit"
            >
            </ButtonComponent>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Login;
