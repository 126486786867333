const columns = (renderValueCell, renderDrillstringCell, renderDrillFluidCell, renderActions, renderCuttingsEditAction, renderTripsEditAction) => [
  {
    field: 'hole_depth',
    headerName: 'Hole Depth (ft)',
    width: 180,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'bit_depth_offset',
    headerName: 'Bit Off-Bottom Offset (ft)',
    width: 260,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(1);
    },
  },
  {
    field: 'rop',
    headerName: 'ROP (ft/hr)',
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'flow_in',
    headerName: 'Flow Rate (gpm)',
    width: 165,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'sbp',
    headerName: 'SBP (psi)',
    width: 140,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'drillStringsId',
    headerName: 'Drillstrings',
    width: 160,
    renderEditCell: renderDrillstringCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'drillFluidsId',
    headerName: 'Drilling Fluids',
    width: 160,
    renderEditCell: renderDrillFluidCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'cuttings',
    headerName: 'Cuttings',
    width: 125,
    renderCell: renderCuttingsEditAction,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'trips',
    headerName: 'Stripping',
    width: 280,
    renderCell: renderTripsEditAction,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  }
];
export default columns;
