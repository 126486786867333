import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import Datagrid from '../Datagrid';
import { Grid, IconButton } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import columns from './table_config';
import { isFullArray } from '../../../utils/validators';
import { CancelPresentationRounded } from '@mui/icons-material';

const AlarmsModalDialog = ({ isOpen, onCloseClick, selectedJob }) => {
  const apiRef = useGridApiRef();
  const alarmsDetails = selectedJob?.alarms || [];

  const data = (() => {
    const { alarms = [] } = alarmsDetails;

    const alarmsRows = alarms.map((a, idx) => ({
      _id: `alarm_${idx}`,
      state: a.state,
      message: a.message,
    }));

    return alarmsRows;
  })();

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {'Alarms'}
          </Grid>
          <Grid item>
          <IconButton
              onClick={onCloseClick}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    isFullArray(data) && (
      <ModalDialog
        title={getModalTitle()}
        isOpen={isOpen}
        onClose={onCloseClick}
      >
        <Grid item xs={12}>
          <Datagrid data={data} columns={columns} apiRef={apiRef} />
        </Grid>
      </ModalDialog>
    )
  );
};

AlarmsModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectedJob: PropTypes.object,
  onCloseClick: PropTypes.func.isRequired,
};

AlarmsModalDialog.defaultProps = {
  selectedJob: {},
};

export default AlarmsModalDialog;
